import NotFoundImg from './page-not-found.svg'
import { twMerge } from 'tailwind-merge'
import { NotificationWithAction } from '../notification-with-action'
import useTranslation from 'next-translate/useTranslation'

export interface PageNotFoundProps {
  className?: string
}

export function PageNotFound({ className = '' }: PageNotFoundProps) {
  const { t } = useTranslation()

  return (
    <NotificationWithAction
      imgSrc={NotFoundImg}
      imgAlt="404 Page Not Found"
      imgClassName="-ml-6"
      heading={t('ui:errors.headings.page-not-found')}
      message={t('ui:errors.messages.page-doesnt-exist')}
      ctaLabel={t('ui:errors.cta-labels.back-to-listings')}
      handleCta={() => window.location.replace(window.location.origin)}
      className={twMerge(`py-10 text-center ${className}`)}
    />
  )
}

export default PageNotFound
