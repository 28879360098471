import dynamic from 'next/dynamic'
import { faFacebookF, faInstagram, faLinkedinIn, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import HouseLogo from '../house-logo/house-logo'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

const FontAwesomeIcon: React.ComponentType<FontAwesomeIconProps> = dynamic(
  () => import('@fortawesome/react-fontawesome').then((pkg) => pkg.FontAwesomeIcon),
  {
    ssr: false,
  }
)

export interface FooterMobileProps {
  containerClassName?: string
}

const social = [
  {
    icon: faFacebookF,
    link: 'https://www.facebook.com/gounreserved/',
  },
  {
    icon: faXTwitter,
    link: 'https://twitter.com/gounreserved',
  },
  {
    icon: faLinkedinIn,
    link: 'https://www.linkedin.com/company/unreserved-inc/',
  },
  {
    icon: faInstagram,
    link: 'https://www.instagram.com/gounreserved/',
  },
  {
    icon: faYoutube,
    link: 'https://www.youtube.com/channel/UC7Mf7TzFFU0qyg0MU09a3GA',
  },
]

export function FooterMobile({ containerClassName = '' }: FooterMobileProps) {
  const { t } = useTranslation('footer')

  const links = [
    {
      name: t('company.terms-of-use'),
      url: '/legal/terms-of-use',
    },
    {
      name: t('company.privacy-policy'),
      url: '/legal/privacy-policy',
    },
  ]

  return (
    <footer
      className={twMerge(`border-shades-200 text-shades-600 relative border-t bg-white p-6`, `${containerClassName}`)}
    >
      <HouseLogo />
      <ul className="float-right flex space-x-2">
        {social.map((network) => (
          <li key={network.link}>
            <a
              href={network.link}
              target="_blank"
              rel="noreferrer"
              className="block flex h-8 w-8 items-center justify-center rounded-full border"
            >
              <FontAwesomeIcon icon={network.icon} />
            </a>
          </li>
        ))}
      </ul>
      <div className="space-y-[11px] pt-[22px] pb-6">
        <p className="text-shades text-sm">{t('copyright')}</p>
        <p className="text-shades text-sm">{t('main-sentence')}</p>
        <p className="text-shades text-sm">{t('trademarks')}</p>
        <div className="space-x-4">
          {links.map((link, i) => (
            <Link key={i} href={link.url} className="text-shades text-sm underline">
              {link.name}
            </Link>
          ))}
        </div>
      </div>
      <button
        className="bg-shades-600 absolute top-6 right-6 flex h-8 w-8 items-center justify-center rounded-full text-white focus:ring-0"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </button>
    </footer>
  )
}

export default FooterMobile
