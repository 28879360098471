import Link from 'next/link'
import HouseLogoImg from './unreserved-house-logo-dark.svg'

/* eslint-disable-next-line */
export interface HouseLogoProps {}

export function HouseLogo(props: HouseLogoProps) {
  return (
    <Link href="/" className="inline-block">
      <HouseLogoImg width="38" alt="Unreserved House Logo" />
    </Link>
  )
}

export default HouseLogo
