
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { FlexCol } from '@unreserved-frontend-v2/ui/flex/flex-col';
import { Footer } from '@unreserved-frontend-v2/ui/footer/footer';
import { FooterMobile } from '@unreserved-frontend-v2/ui/footer/footer-mobile';
import { Spacer } from '@unreserved-frontend-v2/ui/spacer/spacer';
import { AppHeader } from '../../header/app-header';
export interface AppTemplateProps {
    className?: string;
    children?: ReactNode;
}
/**
 * Represents the template for a page (both mobile & desktop). The passed in children will be rendered in a column
 * that centers the content both horizontally and vertically inside of it. The template is designed to take up
 * the entire viewport and includes a <Spacer /> that will take up any remaining vertical space after the children.
 */
export function PageTemplate({ className = '', children }: AppTemplateProps) {
    return (<FlexCol className={twMerge('relative h-screen', className)}>
      <div className="sticky -top-[49px] z-[100] bg-white">
        <AppHeader />
      </div>
      <FlexCol className="flex-grow items-center justify-center bg-shades-25">
        {children}
        <Spacer />
      </FlexCol>
      <div className="hidden md:block">
        <Footer />
      </div>
      <div className="block md:hidden">
        <FooterMobile />
      </div>
    </FlexCol>);
}
export default PageTemplate;

    async function __Next_Translate__getStaticProps__194fb84a002__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//page-template/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194fb84a002__ as getStaticProps }
  